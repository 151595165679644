import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUser, UserRole } from '../../models/IUser';
import { fetchUser } from './actionCreators';
import {
  TAssignmentUpdate,
  TCart,
  TCheckoutStatus,
  TInitialCheckoutResult,
  TPrivateCartItem,
  TPublicCartItem,
} from '../../types';
import {
  getCartFromStorage,
  removeCartFromStorage,
  saveCart,
} from '../../helpers/localStorage';
import {
  isClassSubscriptionIncluded,
  showPostInviteMessage,
} from '../../helpers';

interface UserState {
  user: IUser | null;
  isLoading: boolean;
  isManager: boolean;
  isCartOpen: boolean;
  cart: TCart;
  cartTotal: number;
  cartTotalToPay: number;
}

const initialState: UserState = {
  user: null,
  isLoading: true,
  isManager: false,
  isCartOpen: false,
  cart: {
    public: [],
    private: [],
    processed: [],
    status: 'open',
    createdAt: new Date().getTime(),
  },
  cartTotal: 0,
  cartTotalToPay: 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserState(state) {
      state = initialState;
      return state;
    },
    addPublicCourse(state, { payload }: PayloadAction<TPublicCartItem>) {
      if (state.cart.public.some((item) => item.id === payload.id)) {
        return;
      }
      state.cart.public.push(payload);
      calculateCartTotal(state);
      saveCart(state.cart);
    },
    togglePublicCourse(state, { payload }: PayloadAction<TPublicCartItem>) {
      if (state.cart.public.some((item) => item.id === payload.id)) {
        state.cart.public = state.cart.public.filter(
          (item) => item.id !== payload.id,
        );
      } else {
        state.cart.public.push(payload);
      }
      calculateCartTotal(state);
      saveCart(state.cart);
    },
    toggleClassAssignment(
      state,
      { payload }: PayloadAction<TAssignmentUpdate>,
    ) {
      const { itemId, studentId } = payload;
      const item = state.cart.public.find((item) => item.id === itemId);
      if (!item) {
        return;
      }
      if (!item.studentIds) {
        item.studentIds = [studentId];
      } else if (item.studentIds.includes(studentId)) {
        item.studentIds = item.studentIds.filter((id) => id !== studentId);
      } else {
        item.studentIds.push(studentId);
      }
      state.cart.public = state.cart.public.map((item) =>
        item.id === itemId ? { ...item } : item,
      );
      calculateCartTotal(state);
      saveCart(state.cart);
    },
    removeStudentAssignment(state, { payload }: PayloadAction<string>) {
      state.cart.public = state.cart.public.map((item) => {
        item.studentIds = item.studentIds?.filter((id) => id !== payload);
        return { ...item };
      });
      calculateCartTotal(state);
      saveCart(state.cart);
    },
    addPrivateCourse(state, { payload }: PayloadAction<TPrivateCartItem>) {
      if (state.cart.private.some((item) => item.id === payload.id)) {
        return;
      }
      state.cart.private.push(payload);
      saveCart(state.cart);
    },
    removePrivateCourse(state, { payload }: PayloadAction<TPrivateCartItem>) {
      state.cart.private = state.cart.private.filter(
        (item) => item.id !== payload.id,
      );
      saveCart(state.cart);
    },
    setIsCartOpen(state, action: PayloadAction<boolean>) {
      state.isCartOpen = action.payload;
    },
    setCheckoutStatus(state, action: PayloadAction<TCheckoutStatus>) {
      state.cart.status = action.payload;
      saveCart(state.cart);
    },
    clearCart(state) {
      removeCartFromStorage();
      state.cart.status = 'open';
      state.cart.private = [];
      state.cart.public = [];
      state.cart.processed = [];
      calculateCartTotal(state);
    },
    completeFirstCheckoutStep(
      state,
      action: PayloadAction<TInitialCheckoutResult>,
    ) {
      state.cart.processed = action.payload.processedItems;
      if (!action.payload.itemsToPay.length) {
        state.cart.status = 'completed';
        return;
      }

      state.cart.status = 'second_step';
      state.cart.private = [];
      state.cart.public = action.payload.itemsToPay;
      calculateCartTotal(state);
      saveCart(state.cart);
    },
    setCompanyLogo(state, action: PayloadAction<string>) {
      if (state.user) {
        state.user.company.photo = action.payload;
      }
    },
    setUserAvatar(state, action: PayloadAction<string>) {
      if (state.user) {
        state.user.photo = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action: PayloadAction<IUser>) => {
        state.isLoading = false;
        state.user = action.payload;
        state.isManager = action.payload.role === UserRole.MANAGER;
        // reset cart properties  on login, including assigned students (quantity)
        state.cart.public = state.cart.public.map((item) => {
          item.studentIds = undefined;
          item.included = isClassSubscriptionIncluded(
            item.endTime,
            state.user?.program,
          );
          return item;
        });
        calculateCartTotal(state);
        saveCart(state.cart);

        // show message on the first login after processed invite
        showPostInviteMessage(action.payload.inviteMetadata);
      })
      .addCase(fetchUser.rejected, (state) => {
        state.isLoading = false;
        state.user = null;
      })
      .addCase(fetchUser.pending, (state) => {
        state.cart = getCartFromStorage() ?? state.cart;
        calculateCartTotal(state);
      });
  },
});

const calculateCartTotal = (state: UserState) => {
  state.cartTotal = 0;
  state.cartTotalToPay = 0;
  state.cart.public.forEach(({ price, studentIds, included }) => {
    state.cartTotal += price * (studentIds?.length || 1);
    if (!included) {
      state.cartTotalToPay += price * (studentIds?.length || 1);
    }
  });
};

export const {
  clearUserState,
  completeFirstCheckoutStep,
  addPublicCourse,
  togglePublicCourse,
  addPrivateCourse,
  removePrivateCourse,
  toggleClassAssignment,
  removeStudentAssignment,
  setIsCartOpen,
  clearCart,
  setCheckoutStatus,
  setCompanyLogo,
  setUserAvatar,
} = userSlice.actions;
export default userSlice.reducer;
