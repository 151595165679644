import { ICompany } from './ICompany';
import { TInviteMetadata, TProgram } from '../types';

export interface IUser {
  email: string;
  firstName: string;
  lastName: string;
  photo?: string;
  role: UserRole;
  company: ICompany;
  individual: boolean;
  program: TProgram | null;
  inviteMetadata?: TInviteMetadata;
}

export enum UserRole {
  STUDENT = 'student',
  INSTRUCTOR = 'instructor',
  MANAGER = 'manager',
  ADMIN = 'admin',
}
