import axios from 'axios';

import { showToast } from './index';
import { getAccessToken } from './localStorage';
import { DEFAULT_ERROR_MESSAGE } from './constants';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

axiosInstance.interceptors.request.use((config) => {
  if (process.env.LEARNIT_ENV === 'local') {
    config.withCredentials = true;
  }

  const token = getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!axios.isCancel(error) && error.response?.status >= 500) {
      showToast(DEFAULT_ERROR_MESSAGE, 'error');
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
